<template>
    <div>
        <div>
            <div class="flex justify-between mb-4">
                <div class="border-b border-grayxlight text-[13px]">Основные данные</div>
                <button-icon class="text-darkred opacity-40">
                    <IconTrash />
                </button-icon>
            </div>

            <div class="flex mb-4">
                <div class="mr-5 flex-1">
                    <div class="text-xs text-gray pb-1">Тип дня</div>
                    <dropdown v-model="dayType" :options="store.dayTypes">
                        {{ dayType.name }}
                    </dropdown>
                </div>
                <div class="mr-5 flex-1">
                    <div class="text-xs text-gray pb-1">Должность</div>
                    <dropdown v-model="position" :options="store.positions">
                        {{ position.name }}
                    </dropdown>
                </div>
            </div>

            <checkbox class="text-sm" v-model="isOvertime">Дополнительный табель</checkbox>

            <div class="mb-4">
                <div class="flex justify-between mb-3">
                    <div class="border-b border-grayxlight text-[13px]">Плановая смена</div>
                </div>

                <div class="flex">
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время начала</div>
                        <time-picker v-model="start" :minute-interval="5" />
                    </div>
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время окончания</div>
                        <time-picker v-model="end" :minute-interval="5" />
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <div class="flex justify-between mb-3">
                    <div class="border-b border-grayxlight text-[13px]">Фактическая смена</div>
                </div>

                <div class="flex">
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время начала</div>
                        <time-picker v-model="startFact" :minute-interval="1" :class="shift.violation?.start_status"
                            :disabled="disableTimePicker" />
                        <div class="text-xs" :class="`text-${shift.violation?.start_status}`">{{
                            shift.violation?.start_msg }}</div>
                    </div>
                    <div class="mr-5 flex-1">
                        <div class="text-xs text-gray pb-1">Время окончания</div>
                        <time-picker v-model="endFact" :minute-interval="1" :class="shift.violation?.end_status"
                            :disabled="disableTimePicker" />
                        <div class="text-xs" :class="`text-${shift.violation?.end_status}`">{{ shift.violation?.end_msg
                            }}</div>
                    </div>
                </div>

                <div class="ml-1 mt-5 flex items-center">
                    <icon width="16" height="13" class="mr-1"
                        :class="{ 'text-darkyellow': shift.violation?.general_status === 'moderate', 'text-red': shift.violation?.general_status === 'warning' }"
                        v-if="shift.violation?.general_status !== 'ok'">
                        <IconWarning />
                    </icon>
                    <icon class="mr-1 text-green" v-if="shift.violation?.general_status === 'ok'">
                        <IconApprove />
                    </icon>
                    <span class="text-sm ">{{ message }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { Shift } from '../../types'
import IconTrash from '../../ui/icons/IconTrash.vue'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TimePicker from 'vue3-timepicker'
import { Option } from '../../types'
import IconWarning from '@/ui/icons/IconWarning.vue'
import IconApprove from '@/ui/icons/IconApprove.vue'

dayjs.extend(utc)

import { useStore } from '../../stores/store'

const store = useStore()

interface Props {
    shift: Shift
}

const props = defineProps<Props>()
const shift = ref<Shift>(props.shift)
const emit = defineEmits(['change'])
const isOvertime = ref(shift.value.is_overtime)
const start = ref()
const end = ref()
const startFact = ref<string | null>(null)
const endFact = ref<string | null>(null)


const status = [
    { id: 'ok', text: 'Смена без нарушений' },
    { id: 'moderate', text: 'Смена с нарушениями' },
    { id: 'warning', text: 'Серьезное нарушение, внесите изменения' }
]

const dayType = ref<Option>({ id: shift.value.day_type, name: store.dayTypes.find(item => item.id === shift.value.day_type)?.name! })
const position = ref<Option>({ id: shift.value.position, name: store.positions.find(item => item.id === shift.value.position)?.name! })

start.value = dayjs.utc(shift.value.plan_work_start_at).format('HH:mm')
end.value = dayjs.utc(shift.value.plan_work_end_at).format('HH:mm')

const message = computed(() => {
    return status.find(item => item.id === shift.value.violation?.general_status)?.text
})


startFact.value = dayjs.utc(shift.value.fact_work_start_at).format('HH:mm')
endFact.value = dayjs.utc(shift.value.fact_work_end_at).format('HH:mm')
const disableTimePicker = computed(() => dayjs().diff(dayjs(shift.value.plan_work_end_at)) < 0)


watch([position, dayType, start, end, startFact, endFact, isOvertime], () => {
    shift.value.position = position.value.id
    shift.value.day_type = dayType.value.id
    shift.value.plan_work_start_at = dayjs.utc(`${shift.value.date} ${start.value}`).toISOString()
    shift.value.plan_work_end_at = dayjs.utc(`${shift.value.date} ${end.value}`).toISOString()
    if (dayjs(`${shift.value.date} ${startFact.value}`).isValid()) {
        shift.value.fact_work_start_at = dayjs.utc(`${shift.value.date} ${startFact.value}`).toISOString()
    }

    if (dayjs(`${shift.value.date} ${endFact.value}`).isValid()) {
        shift.value.fact_work_end_at = dayjs.utc(`${shift.value.date} ${endFact.value}`).toISOString()
    }

    shift.value.is_overtime = isOvertime.value
    emit('change', shift.value)
})

</script>

<style>
.text-moderate {
    color: #FED36B
}

.text-ok {
    color: #a6bd63
}

.text-warning {
    color: #FA7E48
}
</style>
