<template>
    <div>
        <div class="border-grayxlight border-b text-sm semibold text-darkgray mb-2">Основные данные</div>
        <div class="flex relative">
            <div class="flex-1 pr-4" v-if="edit">
                <base-input v-model="user.last_name" class="mb-1" @input="validation">Фамилия</base-input>
                <base-input v-model="user.first_name" class="mb-1" @input="validation">Имя</base-input>
                <base-input v-model="user.middle_name" class="mb-1">Отчество</base-input>
            </div>

            <div class="flex-1 pr-4" v-else>
                <EmployeeDataItem label="Фамилия" :value="user.last_name" />
                <EmployeeDataItem label="Имя" :value="user.first_name" />
                <EmployeeDataItem label="Отчество" :value="user.middle_name" v-if="user.middle_name" />
                <EmployeeDataItem label="Табельный код" value="78956744498125" />
            </div>

            <div class="flex-1 pr-4" v-if="edit">
                <div class="mb-1">
                    <div class="text-xs text-gray pb-1">Телефон</div>
                    <div
                        class="w-full h-[26px] text-sm max-w-lg rounded bg-grayxlight text-gray focus:bg-white focus:border-violet focus:outline-violet hover:border-gray border border-solid border-grayxlight px-1">
                        <vue-tel-input v-model="user.phone_number" :onlyCountries="countries"
                            @country-changed="onCountryChanged"></vue-tel-input>
                    </div>
                </div>

                <base-input v-model="user.email" class="mb-1" @input="validation">E-mail</base-input>
            </div>

            <div class="flex-1 pr-4" v-else>
                <EmployeeDataItem label="Телефон" :value="user.phone_number" />
                <EmployeeDataItem label="E-mail" :value="user.email" />
                <EmployeeDataItem label="Статус" :value="isActive" />
            </div>
            <div v-if="!edit" class="absolute right-0 top-0 text-xs cursor-pointer text-graylight" @click="change">
                Изменить
            </div>
        </div>
        <div class="pt-3 text-right" v-if="edit">
            <btn @click="save" :disabled="isDisabled" :class="{ 'opacity-50': isDisabled }">Сохранить</btn>
            <btn class="ml-3" type="secondary" @click="cancel">Отмена</btn>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import EmployeeDataItem from './EmployeeDataItem.vue'
import { useStore } from '@/stores/store'

interface Props {
    user: any
}

const store = useStore()
const { user } = defineProps<Props>()
const isActive = user?.is_active ? 'Доступен' : 'Не доступен'
const edit = ref(false)
const countries = ['ru', 'by', 'kz', 'kg']
const counter = ref(0)
const isDisabled = ref(true)
const userData = ref<any>(null)

const validation = () => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
    if (user.first_name.length > 1 &&
        user.last_name.length > 1 &&
        re.test(user.email)) {
        isDisabled.value = false
    } else {
        isDisabled.value = true
    }
}

const save = async () => {
    const data = {
        first_name: user.first_name,
        last_name: user.last_name,
        middle_name: user.middle_name,
        phone_number: user.phone_number,
        email: user.email
    }
    await store.userEdit(data, user.id)
    userData.value = { ...user }
}

const change = () => {
    edit.value = true
    userData.value = { ...user }
    validation()
}

const cancel = () => {
    edit.value = false
    user.first_name = userData.value.first_name
    user.last_name = userData.value.last_name
    user.middle_name = userData.value.middle_name
    user.email = userData.value.email
    user.phone_number = userData.value.phone_number
}

const onCountryChanged = (country: any) => {
    if (counter.value) user.phone_number = '+' + country.dialCode
    counter.value++
}

</script>

<style>
.vue-tel-input {
    border: none !important;
    font-size: 14px;
}

.vue-tel-input:focus-within {
    box-shadow: none;
}

.vti__phone {
    background: none;
}

.vti__dropdown {
    padding: 2px !important;
}

.vti__dropdown-list.below {
    top: 25px !important;
}

.vti__dropdown-item {
    padding: 3px !important;
    font-size: 12px
}
</style>