<template>
    <ul class="flex cursor-pointer text-[13px]">
        <div class="rounded-l h-[26px] pt-1 px-3"
            :class="{ 'bg-graytitle text-white': store.scheduleSwitcher, 'bg-grayxlight': !store.scheduleSwitcher }"
            @click="store.toggleSwitcher">График</div>
        <div class="rounded-r h-[26px] pt-1 px-3" @click="store.toggleSwitcher"
            :class="{ 'bg-graytitle text-white': !store.scheduleSwitcher, 'bg-grayxlight': store.scheduleSwitcher }">
            Табель</div>
    </ul>
</template>

<script setup lang="ts">
import { useStore } from '@/stores/store'

const store = useStore()
</script>