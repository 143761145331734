<template>
    <div>
        <div class="flex border-b border-grayxlight mb-2">
            <button v-for="tab in tabs" class="text-[11px] text-graylight tab px-1" :key="tab.name"
                @click="selectTab(tab)" :class="{ active: tab.name === selectedTab }">
                {{ tab.label }}
            </button>
        </div>
        <div class="tab-content">
            <slot :name="selectedTab"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

interface Tab {
    name: string;
    label: string;
}

const props = defineProps<{
    initialTab?: string;
    tabs: Tab[];
}>();

const selectedTab = ref(props.initialTab || (props.tabs.length ? props.tabs[0].name : ''));

const selectTab = (tab: Tab) => {
    selectedTab.value = tab.name;
};

// Watch for changes in initialTab prop and update selectedTab accordingly
watch(() => props.initialTab, (newVal) => {
    if (newVal && props.tabs.find(tab => tab.name === newVal)) {
        selectedTab.value = newVal;
    }
});
</script>

<style scoped>
.tabs button {
    margin-right: 10px;

    border: none;
    background: none;
    cursor: pointer;
}

.tab.active {
    font-size: 13px;
    color: #211A4D;
    border-bottom: 1px solid #211A4D
}
</style>