<template>
    <div class="group min-w-12 w-full flex justify-center border-red relative h-16">
        <div v-if="timesheet" class="group text-gray text-[11px] flex flex-col items-center justify-center">
            <div>{{ main }}</div>
            <div>{{ additional }}</div>
            <div class="border-b border-graylight w-6"></div>
            <div>{{ fact }}</div>
            <InfoTooltip :fact :main :additional />
        </div>
        <div v-else class="text-gray text-[11px] flex flex-col items-center justify-center">
            <div>ВЫХ</div>
            <div>-</div>
            <div class="border-b border-graylight w-6"></div>
            <div>-</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Schedule } from '@/types'
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { useStore } from '@/stores/store'
import InfoTooltip from '../schedule/InfoTooltip.vue'

interface Props {
    schedule: Schedule,
    day: number
}

dayjs.extend(duration)

const store = useStore()

const { schedule, day } = defineProps<Props>()

const timesheets = computed(() => store.timesheets.filter(shift => (+dayjs(shift.date).format('D') === day && shift.employment === schedule.id)))
const timesheet = timesheets.value.length ? timesheets.value[0] : null
const main = computed(() => dayjs.duration(timesheet.main_seconds * 1000).format("HH:mm"))
const fact = computed(() => dayjs.duration(timesheet.fact_seconds * 1000).format("HH:mm"))
const additional = computed(() => dayjs.duration(timesheet.additional_seconds * 1000).format("HH:mm"))

</script>
