import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import './style.css'
import './assets/css/theme-mindm.css'
import App from './App.vue'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'
import router from './router'
import { createI18n } from 'vue-i18n'
import messages from './locales'
import vClickOutside from "click-outside-vue3"
import './assets/css/VueTimepicker.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

import Logo from './ui/Logo.vue'
import Button from './ui/Button.vue'
import Input from './ui/Input.vue'
import BaseInput from './ui/BaseInput.vue'
import BaseButton from './ui/BaseButton.vue'
import Icon from './ui/Icon.vue'
import Checkbox from './ui/Checkbox.vue'
import ButtonIcon from './ui/ButtonIcon.vue'
import Dropdown from './ui/Dropdown.vue'

const i18n = createI18n({
    locale: 'ru',
    fallbackLocale: 'ru',
    messages
  })

const globalOptions = {
    mode: 'international',
    autoFormat: false
  };

const app = createApp(App)
const pinia = createPinia()

pinia.use(({ store }) => {
    store.router = markRaw(router)
});

app.use(pinia)
app.use(router)
app.use(i18n)
app.use(VCalendar, {})
app.use(vClickOutside)
app.use(VueTelInput, globalOptions)

app.mount('#app')


app.component('Logo', Logo)
   .component('b-button', Button)
   .component('b-input', Input)
   .component('base-input', BaseInput)
   .component('btn', BaseButton)
   .component('icon', Icon)
   .component('checkbox', Checkbox)
   .component('button-icon', ButtonIcon)
   .component('dropdown', Dropdown)