
import { http, httpCompute } from './index'

export async function getDepartmentTree() {
    return await http.get<any>('/api/departments/tree/')
}

export async function getDepartments() {
    return await http.get<any>('/api/departments/')
}
  
export async function getSchedule(department: string, start: string, end: string) {
    return await http.get<any>(`/api/shifts/schedule/?department=${department}&start_date=${start}&end_date=${end}`)
}

export async function getTimeSheet(department: string, start: string, end: string) {
    return await httpCompute.get<any>(`/api/timesheet/?department_id=${department}&start_date=${start}&end_date=${end}`)
}
  
export async function getPositions() {
    return await http.get<any>('/api/positions')
}

export async function getDayTypes() {
    return await http.get<any>('/api/day-types')
}

export async function createShift(data:any) {
    return await http.post<any>(`/api/shifts/`, data)
}

export async function editShift(data:any) {
    return await http.patch<any>(`/api/shifts/${data.id}/`, data)
}

export async function createShiftPack(data:any) {
    return await http.post<any>('/api/shifts/bulk-create/', data)
}
