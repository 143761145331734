<template>
    <div>
        <Tabs :tabs="tabs" initial-tab="tab1">
            <template v-slot:tab1>
                <div class="flex">
                    <div class="flex-1">
                        <EmployeeDataItem label="Подразделение" value="Магазин двести двенадцать" />
                        <EmployeeDataItem label="Должность" value="Продавец непроизводственн..." />
                        <EmployeeDataItem label="Дата начала" value="01.08.2019" />
                        <EmployeeDataItem label="Дата окончания" value="-" />
                    </div>
                    <div class="flex-1">
                        <EmployeeDataItem label="Доля рабочих часов" value="100%" />
                        <EmployeeDataItem label="Доступность" value="5/2" />
                    </div>
                </div>
            </template>
            <template v-slot:tab2>
                Активность
            </template>
            <template v-slot:tab3>
                Документы
            </template>
            <template v-slot:tab4>
                Smart Scheduling
            </template>
            <template v-slot:tab5>
                Настройки
            </template>
        </Tabs>
    </div>
</template>

<script setup lang="ts">
import Tabs from './Tabs.vue'
import EmployeeDataItem from './EmployeeDataItem.vue';

interface Tab {
    name: string;
    label: string;
}

const tabs: Tab[] = [
    { name: 'tab1', label: 'Трудоустройство' },
    { name: 'tab2', label: 'Активность' },
    { name: 'tab3', label: 'Документы' },
    { name: 'tab4', label: 'Smart Scheduling' },
    { name: 'tab5', label: 'Настройки' }
];
</script>
